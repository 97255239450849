import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { Geolocation } from '@capacitor/geolocation';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AlertController, IonRouterOutlet, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import OneSignal from 'onesignal-cordova-plugin';
import { APP_CONFIG, IAppConfig } from './app.config';
import { AuthService } from './providers/auth-service/auth.service';
import { NotificationService } from './providers/notification-service/notification.service';
import { GlobalEvents } from './services/events.service';
import { StorageService } from './services/storage.service';
// import { OSNotification } from 'onesignal-cordova-plugin/types/Notification';
import { App } from '@capacitor/app';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    geoWatchId: string;
    isUpdateAlertOpen = false;
    datePipe = new DatePipe('en-US');
    checkConnectionInterval;
    @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private storage: StorageService,
        public translate: TranslateService,
        private events: GlobalEvents,
        private alertController: AlertController,
        private http: HttpClient,
        private notificationService: NotificationService
    ) {
        translate.addLangs(['es', 'en']);
        this.events.getObservable().subscribe((e) => this.handleGlobalEvents(e));
        Network.addListener('networkStatusChange', (status) => {
            this.events.publish({ event: 'networkStatusChange', data: status.connected });
            this.checkConnectionStatus();
        });
        this.initializeApp();
    }

    async initializeApp() {
        await this.storage.init();
        const lang = await this.storage.get('lang', 'en');
        this.translate.setDefaultLang(lang);
        const platform = await this.platform.ready();
        this.initNetworkWatch();
        try {
            AuthService.token = await this.storage.get('token');
            const deviceInfo = await Device.getInfo();
            AuthService.deviceModel = `${deviceInfo.manufacturer} - ${deviceInfo.model} - ${deviceInfo.osVersion}`;
            if (platform === 'dom') {
                await this.storePushId((await Device.getId()).uuid);
                window.addEventListener('blur', () => {
                    this.events.activeWindow = false;
                });
                window.addEventListener('focus', () => {
                    this.events.activeWindow = true;
                });
            } else {
                /// ONE SIGNAL SETUP
                OneSignal.setAppId(this.config.pushAppId);
                OneSignal.setNotificationWillShowInForegroundHandler((event) => {
                    const notification = event.getNotification();
                    LocalNotifications.schedule({
                        notifications: [
                            {
                                title: notification.title,
                                body: notification.body,
                                sound: notification.sound,
                                extra: notification,
                                id: Date.now(),
                            },
                        ],
                    });
                    event.complete(null);
                });
                OneSignal.setNotificationOpenedHandler((event) => {
                    if (!event.action.type) {
                        this.handleNotifications(event.notification);
                    }
                });
                OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
                    console.log('User accepted notifications: ' + accepted);
                });
                (OneSignal.getDeviceState as any)(async (state) => {
                    await this.storePushId(state.userId);
                });
                OneSignal.clearOneSignalNotifications();
                /// ONE SIGNAL SETUP
            }
            this.setupGeoWatch();
            this.platform.pause.subscribe(async () => {
                Geolocation.clearWatch({
                    id: this.geoWatchId,
                });
                clearInterval(this.checkConnectionInterval);
            });
            this.platform.resume.subscribe(async () => {
                this.geoWatchId = await this.initGeoWatch();
                this.initNetworkWatch();
            });
            this.platform.backButton.subscribeWithPriority(-1, () => {
                if (!this.routerOutlet.canGoBack()) {
                    App.exitApp();
                }
            });
            LocalNotifications.addListener('localNotificationActionPerformed', (event) => {
                if (event.actionId) {
                    this.handleNotifications(event.notification.extra);
                }
            });
        } catch (error) {}
    }

    async storePushId(pushId: string) {
        await this.storage.set('pushId', pushId);
        AuthService.pushId = pushId;
    }

    handleNotifications(data: any) {
        const additionalData = data.additionalData as any;
        if (additionalData && +additionalData.typeNotif === 5) {
            setTimeout(() => {
                this.notificationService.markNotificationsRead(+additionalData.idNotification);
                this.events.publish({ event: 'go_to_time_off_history', data: +additionalData.idRequestDayOff });
            }, 300);
        }
        if (additionalData && additionalData.typeNotif === 2 && additionalData.link) {
            setTimeout(async () => {
                this.notificationService.markNotificationsRead(+additionalData.idNotification);
                Browser.open({ url: additionalData.link });
            }, 300);
        }
    }

    async handleGlobalEvents(e: { event: string; data: any }) {
        if (e.event === 'NEW_LOGIN') {
            if (!this.isUpdateAlertOpen) {
                this.isUpdateAlertOpen = true;
                const t = await this.translate.get(['NEW_LOGIN_TITLE', 'NEW_LOGIN_TEXT', 'OK']).toPromise();
                const data = e.data.split(',');
                const timestamp = this.datePipe.transform(data[1], 'MM/dd/yyy h:mm a');
                t.NEW_LOGIN_TEXT = t.NEW_LOGIN_TEXT.replace('$device$', data[0]).replace('$date$', timestamp);
                const alert = await this.alertController.create({
                    header: t.NEW_LOGIN_TITLE,
                    message: t.NEW_LOGIN_TEXT,
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: t.OK,
                            handler: () => {
                                this.events.publish({ event: 'LOGOUT', data: { ignorePush: true } });
                                this.isUpdateAlertOpen = false;
                            },
                        },
                    ],
                });
                await alert.present();
            }
        } else if (e.event === 'NEW_UPDATE') {
            if (!this.isUpdateAlertOpen) {
                const t = await this.translate.get(['UPDATE_TITLE', 'UPDATE_TEXT', 'UPDATE_BUTTON']).toPromise();
                const alert = await this.alertController.create({
                    header: t.UPDATE_TITLE,
                    message: t.UPDATE_TEXT,
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: t.UPDATE_BUTTON,
                            handler: () => {
                                let storeUrl;
                                if (this.platform.is('android')) {
                                    storeUrl = this.config.playStore;
                                } else if (this.platform.is('ios')) {
                                    storeUrl = this.config.appStore;
                                }
                                if (storeUrl) {
                                    Browser.open({ url: storeUrl });
                                    this.isUpdateAlertOpen = false;
                                }
                            },
                        },
                    ],
                });
                await alert.present();
                this.isUpdateAlertOpen = true;
            }
        } else if (e.event === 'CHECK_CONNECTION_STATUS') {
            this.checkConnectionStatus();
        }
    }

    async setupGeoWatch() {
        const perm = await Geolocation.checkPermissions();
        if (perm.location === 'granted') {
            setTimeout(async () => {
                this.geoWatchId = await this.initGeoWatch();
            }, 100);
        } else {
            await Geolocation.requestPermissions();
            this.setupGeoWatch();
        }
    }

    initGeoWatch() {
        return Geolocation.watchPosition({ enableHighAccuracy: true }, (location) => {
            this.events.publish({ event: 'LOCATION_UPDATE', data: location && location.coords });
        });
    }

    async checkConnectionStatus() {
        const resp = await this.http
            .head(`${this.config.nodeEndpoint}/healthcheck?hide_loading&silent`)
            .toPromise()
            .catch(() => {
                return 'error';
            });
        if (resp === 'error') {
            this.events.publish({ event: 'INTERNET_CONNECTION', data: false });
        } else {
            this.events.publish({ event: 'INTERNET_CONNECTION', data: true });
        }
    }

    initNetworkWatch() {
        this.checkConnectionInterval = setInterval(async () => {
            this.checkConnectionStatus();
        }, 20000);
    }
}
