import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private _storage: Storage | null = null;

    constructor(private storage: Storage) {
        this.init();
    }

    async init() {
        const storage = await this.storage.create();
        this._storage = storage;
    }

    public set(key: string, value: any) {
        this._storage?.set(key, value);
    }

    public async get(key: string, defaultValue?: string) {
        const value = await this._storage?.get(key);
        return value ? value : defaultValue;
    }

    public async remove(key: string) {
        await this._storage.remove(key);
    }

    public async clear() {
        await this._storage.clear();
    }
}
