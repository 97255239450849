import { InjectionToken  } from '@angular/core';
import { environment } from 'src/environments/environment';

export let APP_CONFIG = new InjectionToken('app.config');

export interface IAppConfig {
    apiEndpoint: string;
    nodeEndpoint: string;
    isProd: boolean;
    appVersion: string;
    appName: string;
    pushAppId: string;
    pushGoogleId: string;
    playStore: string;
    appStore: string;
}

export const AppConfig: IAppConfig = {
    isProd: environment.production,
    apiEndpoint: environment.apiEndpoint,
    nodeEndpoint: environment.nodeEndpoint,
    appVersion: '1.0.31',
    appName: 'TTK',
    pushAppId: '25183fdb-cf53-4ac4-8ebc-7db215aa282f',
    pushGoogleId: '238508452729',
    playStore: 'market://details?id=com.srs.suite.timetrackingassistance',
    appStore: 'itms-apps://apps.apple.com/us/app/srs-time-tracking-assistance/id1539441043',
};
