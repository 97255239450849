import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { Network } from '@capacitor/network';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import {
    Dealer,
    Shift,
    ShiftResponse,
    User,
    convertShittyDealer,
    convertShittyUser,
    responseToShift,
} from 'src/app/models';
import { GlobalEvents } from 'src/app/services/events.service';
import { StorageService } from 'src/app/services/storage.service';
import { BaseService } from '../BaseService';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
    providedIn: 'root',
})
export class ShiftService extends BaseService {
    networkConnected = false;
    activeShiftUid: number;
    lastCoords: {
        latitude: number;
        longitude: number;
    };
    coords: {
        latitude: number;
        longitude: number;
    };
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        http: HttpClient,
        private storage: StorageService,
        globalEvents: GlobalEvents,
        private authService: AuthService,
        private loadingCtrl: LoadingController,
        private translate: TranslateService,
        alertController: AlertController
    ) {
        super(http, globalEvents, alertController);
        this.storage.get('active_shift_uid').then((id) => {
            this.activeShiftUid = id;
        });
        Network.getStatus().then((status) => {
            this.networkConnected = status.connected;
        });
        this.events.getObservable().subscribe((e) => {
            switch (e.event) {
                case 'networkStatusChange':
                    this.networkConnected = e.data;
                    break;
                case 'INTERNET_CONNECTION':
                    this.networkConnected = e.data;
                    break;
                case 'LOCATION_UPDATE':
                    this.coords = e.data;
                    break;
                default:
                    break;
            }
        });
    }

    async checkUserStatus(skipPush?: boolean) {
        if (!this.networkConnected) {
            return true;
        }
        const status = await this.authService.getUserStatus(skipPush);
        if (!status) {
            this.events.publish({ event: 'LOGOUT', data: null });
        }
        return status;
    }

    async getCoordinates() {
        return new Promise<{ latitude: number; longitude: number }>(async (resolve, reject) => {
            const loading = await this.loadingCtrl.create({
                spinner: 'circles',
                duration: 15000,
            });
            loading.present();
            try {
                if (!this.coords) {
                    const geo = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });
                    this.coords = geo.coords;
                }
                loading.dismiss();
                resolve(this.coords);
            } catch (error) {
                loading.dismiss();
                const { ERROR_COORDINATES_HEADER, ERROR_COORDINATES_MSG } = await this.translate
                    .get(['ERROR_COORDINATES_HEADER', 'ERROR_COORDINATES_MSG'])
                    .toPromise();
                this.presentAlert({
                    header: ERROR_COORDINATES_HEADER,
                    message: ERROR_COORDINATES_MSG,
                });
                this.events.publish({ event: 'ENABLE_BUTTONS', data: null });
                reject();
            }
        });
    }

    async getBaseUrl(withoutUser?: boolean, dealerId?: number) {
        const ids = await this.authService.getActiveIds();
        if (withoutUser) {
            return `${this.config.nodeEndpoint}/time/shifts/${dealerId ? dealerId : ids.idDealer}/${
                ids.idDealerProvider
            }`;
        } else {
            return `${this.config.nodeEndpoint}/time/shifts/${dealerId ? dealerId : ids.idDealer}/${
                ids.idDealerProvider
            }/${ids.idUsuario}`;
        }
    }

    async getLastShift(): Promise<Shift> {
        if (await this.checkUserStatus(true)) {
            if (this.networkConnected) {
                const url = await this.getBaseUrl();
                const response: ShiftResponse = await this.apiGet(`${url}/last`);
                const shift = responseToShift(response);
                this.storage.set('last_shift', shift);
                return shift;
            } else {
                const shift = await this.storage.get('last_shift');
                return shift;
            }
        }
    }

    async getShiftHistory(from: string, to: string, userId?: number, dealerId?: number): Promise<Shift[]> {
        if (await this.checkUserStatus()) {
            if (this.networkConnected) {
                const url = userId
                    ? `${await this.getBaseUrl(true, dealerId)}/${userId}/history?from=${from}&to=${to}`
                    : `${await this.getBaseUrl(false, dealerId)}/history?from=${from}&to=${to}`;
                const response: ShiftResponse[] = await this.apiGet(url);
                const shifts = response.map((s) => responseToShift(s));
                await this.storage.set('last_shift_history', shifts);
                return shifts;
            } else {
                const shifts = await this.storage.get('last_shift_history');
                return shifts || [];
            }
        }
    }

    async punchIn(payment?: number): Promise<Shift> {
        if (await this.checkUserStatus()) {
            const coords = await this.getCoordinates();
            const url = `${await this.getBaseUrl()}/punch_in`;
            const data = {
                lat: coords.latitude,
                lng: coords.longitude,
                date: null,
                payment,
            };
            this.activeShiftUid = Date.now();
            this.storage.set('active_shift_uid', this.activeShiftUid);
            if (this.networkConnected) {
                const inside = await this.checkDistance({ lat: data.lat, lng: data.lng }, 'clockin');
                if (!inside) {
                    throw new Error('OUTSIDE_GEOFENCE');
                }
                const resp: ShiftResponse = await this.apiPost(url, data);
                return responseToShift(resp);
            } else {
                throw new Error('NO_INTERNET_CONNECTION');
            }
        }
    }

    async breakIn() {
        if (await this.checkUserStatus()) {
            const coords = await this.getCoordinates();
            const url = `${await this.getBaseUrl()}/break_start`;
            const data = {
                lat: coords.latitude,
                lng: coords.longitude,
                date: null,
            };
            if (this.networkConnected) {
                const inside = await this.checkDistance({ lat: data.lat, lng: data.lng }, 'breakin');
                if (!inside) {
                    throw new Error('OUTSIDE_GEOFENCE');
                }
                await this.apiPost(url, data);
                return true;
            } else {
                throw new Error('NO_INTERNET_CONNECTION');
            }
        }
    }

    async breakOut() {
        if (await this.checkUserStatus()) {
            const coords = await this.getCoordinates();
            const url = `${await this.getBaseUrl()}/break_end`;
            const data = {
                lat: coords.latitude,
                lng: coords.longitude,
                date: null,
            };
            if (this.networkConnected) {
                const inside = await this.checkDistance({ lat: data.lat, lng: data.lng }, 'breakout');
                if (!inside) {
                    throw new Error('OUTSIDE_GEOFENCE');
                }
                await this.apiPost(url, data);
            } else {
                throw new Error('NO_INTERNET_CONNECTION');
            }
        }
    }

    async punchOut() {
        if (await this.checkUserStatus()) {
            const coords = await this.getCoordinates();
            const url = `${await this.getBaseUrl()}/punch_out`;
            const data = {
                lat: coords.latitude,
                lng: coords.longitude,
                date: null,
            };
            if (this.networkConnected) {
                const inside = await this.checkDistance({ lat: data.lat, lng: data.lng }, 'clockout');
                if (!inside) {
                    throw new Error('OUTSIDE_GEOFENCE');
                }
                await this.apiPost(url, data);
            } else {
                throw new Error('NO_INTERNET_CONNECTION');
            }
        }
    }

    async checkDistance(coords, typePunch: 'clockin' | 'clockout' | 'breakin' | 'breakout') {
        const u = await this.authService.getUser();
        let d = await this.authService.getActiveDealer();
        const { data } = await this.apiGet(
            `${this.config.apiEndpoint}/php/api/api.dealers.php?id_dealer=${d.id}&hide_loading`
        );
        d = convertShittyDealer(data);
        let lat = d.lat;
        let lng = d.lng;
        let radius = d.radius;
        let insideRadius = false;
        let mts = 0;
        let dist = 0;
        if ((!lat || !lng || !radius) && (!d.geoFences || !d.geoFences.length)) {
            return true;
        } else {
            const p = await this.authService.getActiveProvider();
            if (d.geoFences.length) {
                for (const fence of d.geoFences) {
                    lat = fence.lat;
                    lng = fence.lng;
                    radius = fence.radius;
                    dist = this.calcCrow(lat, lng, coords.lat, coords.lng);
                    mts = dist * 1000;
                    insideRadius = radius > mts;
                    if (insideRadius) {
                        break;
                    }
                }
            } else {
                dist = this.calcCrow(lat, lng, coords.lat, coords.lng);
                mts = dist * 1000;
                insideRadius = radius > mts;
            }
            const mail = {
                idAuthor: u.id,
                idDealer: d.id,
                idDealerProvider: !u.isDetailer ? null : p.id,
                insideRadio: insideRadius ? '1' : '0',
                typePunch,
                mts: mts.toFixed(0),
                lat,
                lng,
                userLat: coords.lat,
                userLng: coords.lng,
            };
            const lastOutsideAlert = await this.storage.get('last_outside_alert');
            if ((!lastOutsideAlert || lastOutsideAlert !== typePunch) && this.networkConnected) {
                await this.apiPost(`${this.config.apiEndpoint}/php/api/ttk/api.sendemail.php?hide_loading`, mail);
                if (insideRadius) {
                    this.storage.set('last_outside_alert', null);
                } else {
                    this.storage.set('last_outside_alert', typePunch);
                }
            }

            return insideRadius;
        }
    }

    calcCrow(lat1, lon1, lat2, lon2) {
        const R = 6371; // km
        const dLat = this.toRad(lat2 - lat1);
        const dLon = this.toRad(lon2 - lon1);
        lat1 = this.toRad(lat1);
        lat2 = this.toRad(lat2);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d;
    }

    toRad(Value) {
        return (Value * Math.PI) / 180;
    }

    async getDealerShifts(date: string, dealer?: Dealer): Promise<{ shifts: Shift[]; users: User[] }> {
        const { shifts, users } = await this.apiGet(`${await this.getBaseUrl(true, dealer.id)}/${date}/dealer`);
        return {
            shifts: shifts.map((s) => responseToShift(s)),
            users: users.map((u) => convertShittyUser(u)),
        };
    }

    async sleep(millis: number) {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, millis);
        });
    }
    async deleteShift(shift: Shift) {
        return await this.apiDelete(this.config.apiEndpoint + '/php/api/modulos/ttk/punch/' + shift.id);
    }
    async updateCreateShift(shift: Shift, userId?: number) {
        const ids = await this.authService.getActiveIds();
        let _shift;
        if (shift.id) {
            _shift = {
                punchIn: shift.punchIn,
                punchOut: shift.punchOut,
                breakStart: shift.breakStart,
                breakEnd: shift.breakEnd,
                punchInNote: shift.punchInNote,
                punchOutNote: shift.punchOutNote,
                breakEndNote: shift.breakEndNote,
                breakStartNote: shift.breakStartNote,
                idEmployee: shift.idUser,
                idDealer: shift.idDealer,
                idTtk: shift.id,
                idPaymentType: shift.idPaymentType,
            };
        } else {
            _shift = {
                punchIn: shift.punchIn,
                punchOut: shift.punchOut,
                breakStart: shift.breakStart,
                breakEnd: shift.breakEnd,
                punchInNote: shift.punchInNote,
                punchOutNote: shift.punchOutNote,
                breakEndNote: shift.breakEndNote,
                breakStartNote: shift.breakStartNote,
                idEmployee: userId,
                idDealer: shift.idDealer ? shift.idDealer : ids.idDealer,
                idPaymentType: shift.idPaymentType,
            };
        }
        return await this.apiPut(this.config.apiEndpoint + '/php/api/modulos/ttk/punch', _shift);
    }
}
