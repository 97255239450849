import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GlobalEvents {
    breakInterval;
    breakProgress = 0;
    breakStartTime: number;
    breakStopTime: number;
    activeWindow: boolean;

    private queue = new Subject<{ event: string; data: any }>();

    publish(data: { event: string; data: any }) {
        this.queue.next(data);
    }

    getObservable(): Subject<{ event: string; data: any }> {
        return this.queue;
    }

    initBreak(startTime?: number) {
        this.breakStartTime = startTime ? startTime : Date.now();
        this.breakInterval = setInterval(() => {
            const currentTime = Date.now();
            const currDuration = currentTime - this.breakStartTime;
            this.publish({
                event: 'REPORT_BREAK_PROGRESS',
                data: {
                    duration: currDuration,
                },
            });
        }, 1000);
    }

    stopBreak() {
        this.breakStopTime = Date.now();
        this.publish({
            event: 'REPORT_BREAK_END',
            data: {
                start: this.breakStartTime,
                stop: this.breakStopTime,
            },
        });
        clearInterval(this.breakInterval);
    }
}
