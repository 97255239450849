import { Injectable } from '@angular/core';

import { AlertController, LoadingController } from '@ionic/angular';

import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../providers/auth-service/auth.service';
import { AppConfig } from '../app.config';
import { GlobalEvents } from '../services/events.service';
@Injectable({
    providedIn: 'root',
})
export class HttpService implements HttpInterceptor {
    public static pendingRequests = 0;
    public static showLoading_ = false;
    public static loadingCtrl: LoadingController;
    static loading: any;
    errorMsg = 'ERROR_500';

    static turnOnModal() {
        if (!this.showLoading_) {
            this.showLoading_ = true;
            this.showLoading();
        }
        this.showLoading_ = true;
    }

    static turnOffModal() {
        this.pendingRequests--;
        if (this.pendingRequests <= 0) {
            if (this.showLoading_ && this.loading) {
                this.loading.dismiss();
                this.loading = null;
            }
            this.showLoading_ = false;
        }
    }

    static async showLoading() {
        this.loading = await this.loadingCtrl.create({
            spinner: 'circles',
            duration: 15000,
        });
        this.loading.present();
    }

    constructor(
        private alertCtrl: AlertController,
        private loadingCtrl: LoadingController,
        private events: GlobalEvents
    ) {
        HttpService.loadingCtrl = this.loadingCtrl;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        HttpService.pendingRequests++;
        const silentRequest = req.url.includes('silent');
        if (!req.url.includes('hide_loading') && !req.url.includes('i18n') && this.events.activeWindow) {
            HttpService.turnOnModal();
        }
        req = req.clone({
            headers: req.headers.append('Version', AppConfig.appVersion).append('App-name', AppConfig.appName),
        });
        if (AuthService.token) {
            req = req.clone({ headers: req.headers.append('Authorization', AuthService.token) });
        }
        if (AuthService.pushId || AuthService.deviceModel) {
            req = req.clone({
                headers: req.headers
                    .append('Device-id', AuthService.pushId ? AuthService.pushId : '')
                    .append('Device-model', AuthService.deviceModel),
            });
        }
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event.type === 4) {
                    const { body } = event as any;
                    if (body && body.status === 'fail') {
                        if (body.error.code === '-10') {
                            this.events.publish({ event: 'NEW_UPDATE', data: null });
                        } else if (body.error.code === '-11') {
                            this.events.publish({ event: 'NEW_LOGIN', data: body.error.message });
                        } else {
                            this.showError(body.error.message);
                        }
                        throw new Error(body.error);
                    } else {
                        return event;
                    }
                } else {
                    return event;
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.hasOwnProperty('status') && !silentRequest) {
                    if (error.status === 406) {
                        this.showError(error.error.error_data ? error.error.error_data : error.error.error);
                    } else {
                        this.showError('Connection error');
                    }
                }
                return throwError(error);
            }),
            finalize(() => {
                setTimeout(() => {
                    HttpService.turnOffModal();
                }, 300);
            })
        );
    }

    showError(text) {
        HttpService.turnOffModal();
        this.presentAlert({ title: 'Error', message: text });
    }

    async presentAlert(dataAlert: any) {
        const alert = await this.alertCtrl.create({
            header: dataAlert.title,
            message: dataAlert.message,
            buttons: ['OK'],
        });

        await alert.present();
    }
}
